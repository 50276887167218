<template>
  <div class="detail-popup" id="detail-popup" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="loader" v-if="!content">
      <Loading></Loading>
    </div>

    <div v-else>
      <div class="detail-popup-poster-container">
        <div class="detail-popup-poster-container-card" :style="[{ 'background-image': `url(${getPoster()})` }]">
          <div class="detail-popup-poster-container-card-content-tag-container">
            <div :class="[localDisplayLang === 'ara' ? 'tag-right' : 'tag-left']" v-if="isObjectTag(content)">
              <p>{{ $t(`${content.objecttag[0]}`) }}</p>
            </div>
          </div>
        </div>
        <img
          :src="closeButton"
          @click="closeDetail()"
          :class="[localDisplayLang === 'ara' ? 'rev-close' : 'main-close']"
        />
      </div>

      <div class="detail-popup-content" v-if="!readMore" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="detail-popup-content-details">
          <div class="detail-popup-content-details-divide">
            <div class="detail-popup-content-details-divide-contain">
              <p class="detail-popup-content-details-divide-contenttitle">{{ content.title }}</p>
              <div class="detail-popup-content-details-divide-tags">
                <!-- <span class="detail-popup-content-details-divide-tags-item">{{ $t(`${content.objecttype}`) }}</span> -->
                <span class="detail-popup-content-details-tags-item">{{ $t(`${content.category}`) }}</span>
                <span v-if="content.genre" class="bar"></span>
                <span class="detail-popup-content-details-divide-tags-item">{{ content.genre }}</span>
                <span v-if="content.contentlanguage" class="bar"></span>
                <span class="detail-popup-content-details-divide-tags-item">{{
                  content.contentlanguage[0] ? $t(`${content.contentlanguage[0]}`) : ""
                }}</span>
                <span v-if="content.productionyear" class="bar"></span>
                <span class="detail-popup-content-details-divide-tags-item">{{ content.productionyear }}</span>
                <span v-if="content.pgrating" class="bar"></span>
                <span class="detail-popup-content-details-divide-tags-item">{{ content.pgrating }}</span>
              </div>
            </div>

            <div class="detail-popup-content-details-divide-icons">
              <div class="detail-popup-content-details-divide-icons-btn">
                <LikeBtn :content="content" :width="'20px'" :height="'20px'" class="hover-change"></LikeBtn>
              </div>
              <div class="detail-popup-content-details-divide-icons-btn">
                <DislikeBtn :content="content" :width="'20px'" :height="'20px'" class="hover-change"></DislikeBtn>
              </div>
              <div class="detail-popup-content-details-divide-icons-btn">
                <WatchListBtn :content="content" :width="'20px'" :height="'20px'" class="hover-change"></WatchListBtn>
              </div>
              <div class="detail-popup-content-details-divide-icons-btn">
                <detailShare :content="content" :width="'20px'" :height="'20px'" class="hover-change"></detailShare>
              </div>
            </div>
          </div>

          <div
            v-if="content.category === 'TVSHOW' && content.seasoncount !== 0"
            class="detail-popup-content-details-seasoncount"
          >
            <img
              src="@/assets/icons/season-light.svg"
              alt
              :class="[localDisplayLang === 'ara' ? 'margin-left' : 'margin-right']"
            />
            <p>{{ content.seasoncount }} {{ content.seasoncount === 1 ? $t("Season") : $t("Seasons") }}</p>
          </div>

          <div class="detail-popup-content-details-metadata">
            <div class="detail-popup-content-details-metadata-description" v-if="content.shortdescription">
              <span class="detail-popup-content-details-metadata-description-short">{{
                content.shortdescription.substring(0, 150)
              }}</span>
              <span
                class="detail-popup-content-details-metadata-description-short readmore"
                @click="readMore = true"
                v-if="(getStringLength && getStringLength > 180) || content.longdescription"
                ><span class="three_dots">...</span> {{ $t("read more") }}</span
              >
            </div>
          </div>
        </div>

        <div class="detail-popup-content-button" v-if="isButtonEnable || content.trailer">
          <button
            v-if="isButtonEnable && contentTags !== 'upcoming'"
            class="button-primary btn"
            @click="buttonHandler()"
          >
            {{ $t(buttonStatus) }}
          </button>
          <button
            class="button-secondary btn"
            @click="watchTrailer()"
            v-if="content.trailer && content.trailer.length > 0"
          >
            {{ $t("WATCH TRAILER") }}
          </button>
        </div>
      </div>

      <div class="detail-popup-long-description" v-if="readMore" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="detail-popup-long-description-container">
          <img src="@/assets/icons/ClosePopup.svg" alt="close" @click="readMore = false" />
          <span>{{ content.longdescription || content.shortdescription }}</span>
        </div>
      </div>

      <div class="detail-popup-component-tabs" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <ul class="detail-popup-component-tabs-tablist">
          <li
            v-for="(item, index) in tabList"
            :key="index"
            @click="selectTab(index)"
            :class="selectedSection === index ? activeTab : ''"
          >
            {{ $t(item) }}
          </li>
        </ul>
      </div>

      <div
        class="detail-popup-tab-component"
        v-if="tabList.length > 0"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <keep-alive v-for="(item, index) in tabList" :key="index">
          <component
            v-if="index == selectedSection"
            :is="item"
            :content="content"
            :playerInstance="playerInstance"
            :changeStyles="true"
          ></component>
        </keep-alive>
      </div>
    </div>
    <transition>
      <subscribe
        v-if="isSubscribe"
        :subscribePayload="subscribePayload"
        :closePopup="() => toggleSubscribePopup(false)"
      />
    </transition>
    <transition>
      <payment v-if="isPayment" :paymentDetail="paymentDetail" :closePopup="() => togglePaymentPopup(false)" />
    </transition>
    <transition>
      <NoorPayment
        v-if="isNoorPayment"
        :paymentDetail="noorPaymentDetail"
        :closePopup="() => toggleNoorPayment(false)"
      />
    </transition>
    <transition>
      <checkout
        v-if="isCheckout"
        :checkoutDetails="checkoutPaymentDetails"
        :closePopup="() => toggleCheckoutPopup(false)"
      />
    </transition>
    <transition>
      <telcoOtp
        v-if="isTelcoOTP"
        :paymentInfo="telcoPaymentInfo"
        :planInfo="telcoPlanInfo"
        :isMobileno="telcoMobileNumber"
        :gatewayInfo="telcoGatewayInfo"
        :closePopup="() => toggleTelcoOTP(false)"
      >
      </telcoOtp>
    </transition>
  </div>
</template>

<script>
import { vLivePlayer, vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
import { store } from "@/store/store";

import { showSnackBar } from '@/utilities';

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      showDarkClose: true,
      closeButton: require("@/assets/icons/close_with_shadow.svg"),
      castData: [],
      crewData: [],
      readMore: false,
      activeComponent: null,
      sections: [],
      activeTab: "active-tab",
      selectedSection: null,
      changeStyles: false,
      episodeDetailArr: null,
      checkEpisode: false,
      isButtonEnable: false,
      buttonStatus: "",
      isSubscribe: false,
      subscriberpayload: {},
      filteredAvailabilities: [],
      pricemodel: [],
      availabilities: [],
      providerUniqueId: _providerId,
      subscriptions: [],
      purchases: [],
      content: null,
      contentid: null,
      detailPoster: null,
      isPayment: false,
      paymentDetail: null,

      tabList: [],
      localDisplayLang: null,
      subscribePayload: null,
      isSubscribe: false,
      isNoorPayment: false,
      noorPaymentDetail: null,
      isCheckout: false,
      checkoutPaymentDetails: null,
      playbackInitiated: false,

      isTelcoOTP: false,
      telcoPaymentInfo: null,
      telcoGatewayInfo: null,
      telcoPlanInfo: null,
      telcoMobileNumber: false,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "appConfig"]),
    contentTags() {
      return this.content.tags[0].toLowerCase();
    },

    getStringLength() {
      console.log("THE LONG DESCRIPTION", this.content);

      if (this.content.longdescription || this.content.shortdescription) {
        return this.content.longdescription
          ? this.content.longdescription.length
          : this.content.shortdescription.length;
      }

      return null;
    },
  },
  watch: {
    $route(to, from) {
      console.log("Route to : ", to);
      console.log("Route from : ", from);

      if (to.name == "detail" && from.name == "detail") {
        this.selectedSection = null;
        this.tabList = [];
        this.content = null;
        this.contentid = to.params.contentid;

        this.getContentDetail(this.contentid);
      }
    },
    subscriberid() {
      //fetch detail card button status buy or watchnow.
      if (this.content.category !== "TVSHOW") {
        this.getAvailability(this.content);
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        if (this.content && this.content.category !== "TVSHOW") {
          this.getAvailability(this.content);
        }
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.contentid = this.$route.params.contentid;
    this.getContentDetail(this.contentid);

    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
  },
  mounted() {

    let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

    if (subscriberProfileDetails) {
      subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
    }

    //content detail response.
    eventBus.$on(`contentDetail-response-${this.contentid}`, (response) => {
      if (!response.reason) {
        this.content = response.data;

        // if (
        //   subscriberProfileDetails &&
        //   subscriberProfileDetails.kidsmode === "YES" &&
        //   this.content.defaultgenre !== "KIDS"
        // ) {
        //   showSnackBar(this.$t("Content is not accessible"));
        //   this.$router.replace("/");
        // } 

        setTimeout(() => {
          if (this.content.category !== "TVSHOW") {
            this.getAvailability(this.content);
          }
        }, 1000);
        this.fetchTabList();
        this.selectTab(0);
      }
    });

    //payment event.
    eventBus.$on("paymentPopup", (response) => {
      this.paymentDetail = response;
      this.togglePaymentPopup(response.state);
    });

    eventBus.$on("noorPayments", (response) => {
      this.noorPaymentDetail = response;
      this.toggleNoorPayment(response.state);
    });

    eventBus.$on("showCheckout", (response) => {
      this.checkoutPaymentDetails = response;
      this.toggleCheckoutPopup(true);
    });

    eventBus.$on("replace-content", (content) => {
      eventBus.$on(`contentDetail-response-${content.objectid}`, (response) => {
        console.log("Content detail response $route : ", response);
        if (!response.reason) {
          this.content = response.data;

          if (
          subscriberProfileDetails &&
          subscriberProfileDetails.kidsmode === "YES" &&
          this.content.defaultgenre !== "KIDS"
          ) {
            showSnackBar(this.$t("Content is not accessible"));
            this.$router.replace("/");
          } 

          setTimeout(() => {
            if (this.content.category !== "TVSHOW") {
              this.getAvailability(this.content);
            }
          }, 1000);
          this.fetchTabList();
          this.selectTab(0);
        }
      });
      // eventBus.$off(`contentDetail-response-${this.contentid}`);
      this.$router.replace({ name: "detail", params: { contentid: content.objectid } });
    });

    eventBus.$on("subscribePopup", (response) => {
      console.log("subscribePopup response", response);
      this.subscribePayload = response;
      this.toggleSubscribePopup(true);
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });

    eventBus.$on("telcoOtpPopup", (response) => {
      console.log("oreedo response in header", response);
      this.telcoPaymentInfo = response.paymentInfo;
      this.telcoPlanInfo = response.planInfo;
      this.telcoGatewayInfo = response.gatewayInfo;
      this.telcoMobileNumber = response.isMobileno;
      this.toggleTelcoOTP(response.state);
    });

    // eventBus.$on("close-detail-popup", () => {
    //   this.closePopup();
    // });
  },
  beforeDestroy() {
    eventBus.$off(`contentDetail-response-${this.contentid}`);

    eventBus.$off("telcoOtpPopup");
    console.log("Mobile detail component destroyed");
  },
  methods: {
    toggleTelcoOTP(state) {
      this.isTelcoOTP = state;
    },

    togglePaymentPopup(state) {
      this.isPayment = state;
    },
    //get content detail.
    getContentDetail(contentid) {
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };
      eventBus.$emit("getContentDetail", payload);
    },
    //close detail route.
    closeDetail() {
      // this.$router.go(-1);
      this.$router.push("/");
    },
    isObjectTag(content) {
      if (Array.isArray(content.objecttag)) {
        return content.objecttag.length === 0 ? false : true;
      } else {
        return content.objecttag === null ? false : true;
      }
    },
    fetchTabList() {
      if (this.content.category === "MOVIE" && this.content.trailer && this.content.trailer.length > 0) {
        this.tabList = ["Trailer", "Related"];
      } else if (this.content.category === "MOVIE") {
        this.tabList = ["Related"];
      } else if (this.content.category === "TVSHOW" && this.content.trailer && this.content.trailer.length > 0) {
        this.tabList = ["Episodes", "Trailer", "Related"];
      } else if (this.content.category === "TVSHOW") {
        this.tabList = ["Episodes", "Related"];
        if (this.contentTags === "upcoming") {
          this.tabList.splice(0, 1);
        }
      }
    },
    selectTab(index) {
      this.selectedSection = index;
      // this.activeComponent = this.tabList[index];
    },
    watchTrailer() {
      //To aviod drm check.
      console.log("what is the content in watch trailer--------", this.content);
      this.showPlayer();
      this.loadContentMetaData(this.content, null);
      this.content.isDrmContent = false;
      this.content.trailerUrl = this.content.trailer[0].filelist[0].filename;

      let contentList = [this.content];
      console.log("what is the content list===", contentList);
      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      console.log("what is the playlist========", playlist);
      this.playerInstance.loadPlaylist(playlist, contentList.length);
      console.log(playlist);
      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();
      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    loadNextContentData(endedContentIndex) {
      this.hidePlayer();
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    buttonHandler() {
      if (!this.subscriberid && (this.buttonStatus === "SUBSCRIBE & WATCH" || this.buttonStatus === "LOGIN & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        this.subscribePayload = { listType: "onlySubscribe" };
        this.toggleSubscribePopup(true);
        // eventBus.$emit("subscribePopup", payload);

        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "PLAY NOW") {
        this.content.isDrmContent = true;
        this.showPlayer();
        this.loadContentMetaData(this.content, null);

        if (this.content.streamUrl == undefined) {
          this.fetchStreamUrlTask = this.fetchContentStreamUrl(this.content);

          this.fetchStreamUrlTask
            .then((resp) => {
              this.handleFetchContentStreamUrl(resp, this.content);

              let contentList = [this.content];
              let playlist = this.buildPlayerPlaylist(contentList, false, 0);
              this.playerInstance.loadPlaylist(playlist, contentList.length);
              this.playerInstance.loadContent(0).then(() => {
                this.playerInstance.play().then(() => {
                  console.log("Playback successful");
                });
              });
            })
            .catch((error) => {
              console.log("Content playback failed : ", error);
              this.hidePlayer();
            });
        } else {
          let contentList = [this.content];
          let playlist = this.buildPlayerPlaylist(contentList, false, 0);
          this.playerInstance.loadPlaylist(playlist, contentList.length);
          this.playerInstance
            .loadContent(0)
            .then(() => {
              this.playerInstance.play().then(() => {
                console.log("Playback successful");
              });
            })
            .catch((error) => {
              console.log("Content playback failed : ", error);
              this.hidePlayer();
            });
        }
      }
    },
    getAvailability(contentItem) {
      if (contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        this.isButtonEnable = true;
        let packageDetails = this.getFilteredPackage(contentItem);

        console.log("package details", packageDetails);

        let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        let pricemodel = availPlanList.pricemodel[0];
        //based on pricemodel of availabilities assigning buttonstatus.
        if (pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if (pricemodel.includes("FREE")) {
          this.buttonStatus = "LOGIN & WATCH";
        }

        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        console.log("filtered availabilities", filteredAvailabilityList);

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                //check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY NOW";
                  contentItem.videoType = "Content";
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              console.log(
                "content price model is not free and user has to subscribe to the plan to watch this content"
              );
            }
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            this.isButtonEnable = true;
            this.buttonStatus = "PLAY NOW";
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },

    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];

      console.log("availabilities", this.availabilities);

      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });

      console.log("packageList", packageList);

      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },

    buildContentStreamRequest(contentItem) {
      console.log("Build content stream url request content : ", contentItem);
      let temporaryVideoTag = document.createElement("video");
      let platformOs = navigator.platform;

      let payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          payload.params.mpegtspackage = "YES";
        }
      }

      return payload;
    },

    fetchContentStreamUrl(contentItem) {
      console.log("Fetch stream url content : ", contentItem);
      return store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem));
    },

    handleFetchContentStreamUrl(response, contentItem) {
      console.log("fetch content stream response", response);
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';

    },
    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: isTrailer ? false : content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: contentHead,
      });

      return playlistItem;
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    createPlayerInstance(content) {
      let contentList = [content];
      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);
      console.log(playlist);
      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });

      // let playlist = [];
      //
      // let playlistItem = this.createPlayerPlaylistItem(content);
      // console.log(playlistItem);
      // playlist.push(playlistItem);
      //
      // this.playerInstance.loadContent(playlist)
      //   .then(() => {
      //     this.playerInstance
      //       .play()
      //       .then(() => {
      //         console.log("Playback successful in app");
      //       })
      //       .catch(error => {
      //         console.log("Error name : ", error.name);
      //         console.log("Error message : ", error.message);
      //       });
      //   })
      //   .catch(error => {
      //     console.log("Playback error in app : ", error.name);
      //     console.log("Playback error in app : ", error.message);
      //   });
    },

    getPoster() {
      let content = this.content;
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },

    toggleSubscribePopup(state) {
      this.isSubscribe = state;
    },

    toggleNoorPayment(state) {
      this.isNoorPayment = state;
    },

    toggleCheckoutPopup(state) {
      this.isCheckout = state;
    },
  },
  components: {
    LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
    DislikeBtn: () => import(/* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"),
    WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
    detailShare: () => import(/* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"),
    Related: () => import(/* webpackChunkName: "related" */ "@/components/Templates/relatedCarousel.vue"),
    Episodes: () => import(/* webpackChunkName: "episodes" */ "@/components/Templates/episodesCarousel.vue"),
    Trailer: () => import(/* webpackChunkName: "trailers" */ "@/components/MobileTemplates/trailerListing.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
    NoorPayment: () => import(/* webpackChunkName: "Noor Payment" */ "@/components/Popups/NoorPayments/payment.vue"),
    checkout: () => import(/* webpackChunkName: "checkout" */ "@/components/gateways/checkout.vue"),
    payment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"),
    telcoOtp: () => import(/* webpackChunkName: "telcoOtp" */ "@/components/Popups/telcoOtp.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./detail.scss"
</style>
